import { createStore } from 'vuex'

export default createStore({
    state: {
        products: [
            {
                id: 1,
                name: 'Светильник Light',
                price: 2000,
                category: '',
                isHit: true,
                isStonks: true,
                isAdvice: true,
                isEco: true,
                isWaterproof: true,
                isEasyMount: true,
                structure: 'Светильник серии light от отечественного производителя',
                url: 'cat-img.png',
                url1: '',
                url2: '',
                url3: '',
                quantity: 1,
                comment: 'Для данного светильника подойдет лампочка ... Для данного светильника подойдет лампочка'
            },
            {
                id: 2,
                name: 'Светильник Light Star premium lamp grey version',
                price: 2500,
                category: '',
                isHit: true,
                isStonks: false,
                isAdvice: false,
                isEco: false,
                isWaterproof: false,
                isEasyMount: false,
                structure: 'Светильник серии light от отечественного производителя',
                url: 'cat-img.png',
                url1: '',
                url2: '',
                url3: '',
                quantity: 1,
                comment: 'Для данного светильника подойдет лампочка ... Для данного светильника подойдет лампочка'
            },
            {
                id: 3,
                name: 'Светильник Light Star premium lamp grey version',
                price: 5000,
                category: '',
                isHit: false,
                isStonks: false,
                isAdvice: false,
                isEco: false,
                isWaterproof: true,
                isEasyMount: false,
                structure: 'Светильник серии light от отечественного производителя',
                url: 'cat-img.png',
                url1: '',
                url2: '',
                url3: '',
                quantity: 1,
                comment: 'Для данного светильника подойдет лампочка ... Для данного светильника подойдет лампочка'
            },
            {
                id: 4,
                name: 'Светильник Light Star premium lamp grey version',
                price: 5000,
                category: '',
                isHit: false,
                isStonks: false,
                isAdvice: false,
                isEco: false,
                isWaterproof: false,
                isEasyMount: false,
                structure: 'Светильник серии light от отечественного производителя',
                url: 'product.png',
                url1: '',
                url2: '',
                url3: '',
                quantity: 1,
                comment: 'Для данного светильника подойдет лампочка ... Для данного светильника подойдет лампочка'
            },
            {
                id: 5,
                name: 'Светильник Light Star premium lamp grey version',
                price: 5000,
                category: '',
                isHit: false,
                isStonks: false,
                isAdvice: false,
                isEco: false,
                isWaterproof: false,
                isEasyMount: true,
                structure: 'Светильник серии light от отечественного производителя',
                url: 'product.png',
                url1: '',
                url2: '',
                url3: '',
                quantity: 1,
                comment: 'Для данного светильника подойдет лампочка ... Для данного светильника подойдет лампочка'
            },
            {
                id: 6,
                name: 'Светильник Light Star premium lamp grey version',
                price: 5000,
                category: '',
                isHit: false,
                isStonks: false,
                isAdvice: false,
                isEco: false,
                isWaterproof: false,
                isEasyMount: false,
                structure: 'Светильник серии light от отечественного производителя',
                url: 'product.png',
                url1: '',
                url2: '',
                url3: '',
                quantity: 1,
                comment: 'Для данного светильника подойдет лампочка ... Для данного светильника подойдет лампочка'
            },
            {
                id: 7,
                name: 'Светильник Light Star premium lamp grey version',
                price: 5000,
                category: '',
                isHit: false,
                isStonks: false,
                isAdvice: false,
                isEco: false,
                isWaterproof: false,
                isEasyMount: false,
                structure: 'Светильник серии light от отечественного производителя',
                url: 'product.png',
                url1: '',
                url2: '',
                url3: '',
                quantity: 1,
                comment: 'Для данного светильника подойдет лампочка ... Для данного светильника подойдет лампочка'
            },
            {
                id: 8,
                name: 'Светильник Light Star premium lamp grey version',
                price: 5000,
                category: '',
                isHit: false,
                isStonks: false,
                isAdvice: false,
                isEco: false,
                isWaterproof: false,
                isEasyMount: false,
                structure: 'Светильник серии light от отечественного производителя',
                url: 'product.png',
                url1: '',
                url2: '',
                url3: '',
                quantity: 1,
                comment: 'Для данного светильника подойдет лампочка ... Для данного светильника подойдет лампочка'
            },
            {
                id: 9,
                name: 'Светильник Light Star premium lamp grey version',
                price: 5000,
                category: '',
                isHit: false,
                isStonks: false,
                isAdvice: false,
                isEco: false,
                isWaterproof: false,
                isEasyMount: false,
                structure: 'Светильник серии light от отечественного производителя',
                url: 'product.png',
                url1: '',
                url2: '',
                url3: '',
                quantity: 1,
                comment: 'Для данного светильника подойдет лампочка ... Для данного светильника подойдет лампочка'
            },
        ],
        cardsPerPage: 9,
        totalPages: 0,
        page: 0,
        cart: [],
        selectedCategory: '',
        selectedManufacturer: '',
        selectedType: ''
    },
    getters: {
        PRODUCTS(state){
            return state.products;
        },
        SELECTED_CATEGORY(state){
            return state.selectedCategory
        },
        SELECTED_MANAFACTURER(state){
            return state.selectedManufacturer
        },
        SELECTED_TYPE(state){
            return state.selectedType
        }
    },
    mutations: {
        SET_CART: (state, product)=>{
            if(state.cart.length){
                let isProductExist = false;
                state.cart.map(function(item){
                    if(item.id === product.id){
                        isProductExist = true;
                        item.quantity++
                    }
                })
                if(!isProductExist){
                    state.cart.push(product)
                }
            }else{
                state.cart.push(product)
            }
        },
        REMOVE_FROM_CART: (state, index)=>{
            state.cart.splice(index, 1)
        },
        INCREASE: (state, index)=>{
            state.cart[index].quantity++
            
        },
        DECREASE: (state, index)=>{
            if (state.cart[index].quantity > 1){
                state.cart[index].quantity--
            }
        },
        SET_SELECTEDCATEGORY: (state, category)=>{
            state.selectedCategory = category
        },
        SET_MANAFACTURER: (state, manafacturer)=>{
            state.selectedManufacturer = manafacturer
        },
        SET_TYPE: (state, type)=>{
            state.selectedType = type
        },
    },
    actions: {
        ADD_TO_CART({commit}, product){
            commit('SET_CART', product);
        },
        INCREASE_QTY({commit}, index){
            commit('INCREASE', index)
        },
        DECREASE_QTY({commit}, index){
            commit('DECREASE', index)
        },
        DELETE_FROM_CART({commit}, index){
            commit('REMOVE_FROM_CART', index)
        },
        SELECT_CATEGORY({commit}, category){
            commit('SET_SELECTEDCATEGORY', category)
        },
        SELECT_MANAFACTURER({commit}, manafacturer){
            commit('SET_MANAFACTURER', manafacturer)
        },
        SELECT_TYPE({commit}, type){
            commit('SET_TYPE', type)
        }
    }
})
